import React from 'react';
import { AppBar, Toolbar, Container, Box, CssBaseline } from '@mui/material';

const FixedLayout = ({ header, footer, children,color="#fff" }) => {
  return (
    <>
      {/* <CssBaseline /> */}
      <AppBar position="sticky" color='default'  sx={{p:0,m:0,b:0,backgroundColor:"#FFF"}}>
        {/* <Toolbar sx={{p:0,m:0,b:0}}> */}
          {header}
        {/* </Toolbar> */}
      </AppBar>
      {/* <Toolbar /> Spacer for the header */}
      <Box 
      sx={{ flex: 1, py: { xs: 2, sm: 3, md: 4 },m:0,
       mb: 8,backgroundColor:`${color}`}}>
        {children}
      </Box>
      <Box
        component="footer"
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          textAlign: 'center',
          bgcolor: 'background.paper',
        }}
      >
        {footer}
      </Box>
    </>
  );
};

export default FixedLayout;
