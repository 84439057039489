import React from 'react'
import Grid from '@mui/material/Grid';
import style from './HomePageMenu.module.css';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PaymentIcon from '@mui/icons-material/Payment';
import { useNavigate } from 'react-router-dom';
const HomePageMenu = () => {
    const navigate = useNavigate();
  return (
    <>
    <Grid container sx={0}>
        <Grid xs={12} md={12} className={style.heading}>
            Sales Transactions
        </Grid>
        <Grid xs={3} md={3} className={style.iconcon} onClick={(e)=>{navigate("/billlist")}}>
          <div>
            <PointOfSaleIcon className={style.iconcolor}/>
          </div>
          <h5>Bill/Invoice</h5>
        </Grid>
        <Grid xs={3} md={3} className={style.iconcon}>
          <div>
            <PaymentIcon className={style.iconcolor}/>
          </div>
          <h5>Received Payment</h5>
        </Grid>
      </Grid>
    </>
  )
}

export default HomePageMenu