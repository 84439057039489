import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import style from "./Bill.module.css";
import Toppagebox from "../GlobalComponent/Toppagebox";
import {
  Button,
  Drawer,
  InputAdornment,
  Paper,
  TextField,
  Chip,
} from "@mui/material";
import { useForm, Controller, SubmitHandler, useWatch } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import PercentIcon from "@mui/icons-material/Percent";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import {
  formatUTCDate,
  handleFocus,
  handleMaxLengthInput,
} from "../../MIS/Global";
import { useDispatch, useSelector } from "react-redux";
import {
  accordionFlagSet,
  getBillPert,
  getMaxBillNo,
  handleCalcBill,
  handleClearValue,
  itemDrawerSet,
  saveBill,
  setUpdatedItemList,
} from "../../API/Reducer/billReducer";
import ItemBill from "./ItemBill";
import ItemBillList from "./ItemBillList";
import { makeStyles } from "@material-ui/core";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TagIcon from "@mui/icons-material/Tag";
import AlertComp from "../AlertMessageCom/AlertComp";
import SearchIcon from "@mui/icons-material/Search";
import { useParams } from "react-router-dom";
import FixedLayout from "../Layout/FixedLayout";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-input": {
      color: "#fff", // Set the text color for enabled state
    },
    "& .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px #fff inset", // Remove autofill background color
      WebkitTextFillColor: "#fff", // Ensure text color is set for autofill
    },
    "& .MuiInputBase-input[disabled]": {
      color: "#000", // Set the text color for disabled state
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#ccc", // Set the placeholder color
    },
    "& .MuiInputBase-input[disabled]::placeholder": {
      color: "#ccc", // Set the placeholder color for disabled state
    },
  },
});

const Bill = () => {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { itemDrawer, accordionFlag, isRoundoff, itemList, billData } =
    useSelector((state) => state.bill);
  const [selectedDate, setSelectedDate] = useState(
    dayjs(formatUTCDate(new Date(), "ymd"))
  );
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setFocus,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      id: 0,
      pid: 0,
      pname: "",
      pmob: "",
      billno: 0,
      edate: "",
      istype: 0,
      remark: "",
      address: "",
      raw: 0,
      rawamt: 0,
      itemtot: 0,
      subtot: 0,
      descper: 0,
      descamt: 0,
      desctot: 0,
      gstper: 0,
      gstamt: 0,
      gsttot: 0,
      roundoff: 0,
      netamt: 0,
      recvamt: 0,
      balamt: 0,
      isstatus: 0,
      grate: 0,
      srate: 0,
      cmpid: 0,
      uid: 0,
    },
  });

  const [formData, setFormData] = useState({});

  const onSubmit = (data) => {
    dispatch(saveBill(data));
  };
  const onSubmitChange = (data) => {
    // console.log(getValues('itemtot'));
    setFormData(getValues());
    setTimeout(() => {
      dispatch(handleCalcBill(getValues(), setValue));
    }, 1000);
  };
  useEffect(() => {
    setTimeout(() => {
      dispatch(handleCalcBill(getValues(), setValue));
    }, 1000);
  }, []);

  const onError = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    setFocus(firstErrorField);
  };
  const handleDateChange = (newValue) => {
    if (newValue) {
      const currentTime = dayjs();
      const newDateTime = newValue
        .hour(currentTime.hour())
        .minute(currentTime.minute())
        .second(currentTime.second());
      setSelectedDate(newDateTime);
      // setValue('edate', newDateTime.format('YYYY-MM-DD HH:mm'));
      setValue("edate", newDateTime.format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    const totalAmount = itemList
      .reduce((acc, item) => acc + Number(item?.netamt), 0)
      .toFixed(2);
    setValue("itemtot", Number(totalAmount).toFixed(2));
    setTimeout(() => {
      dispatch(handleCalcBill(getValues(), setValue));
    }, 500);
  }, [itemList]);

  useEffect(() => {
    if (id && billData.length > 0) {
      dispatch(getBillPert(id, setValue, setSelectedDate));
    } else {
      if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
        const url = new URL(window.location);
        const pathParts = url.pathname.split("/");
        if (pathParts.length > 2 && pathParts[1] === "bill") {
          const newPathname = `/${pathParts[1]}`;
          window.history.replaceState({}, document.title, newPathname);
        }
      }
      dispatch(getMaxBillNo({ cmpid: 0 }, setValue));
    }
  }, [id]);
  const handleSearchClick = () => {
    dispatch(setUpdatedItemList([]));
  };

  const headerContent = (
    <Toppagebox
      func={handleSearchClick}
      back="home"
      name="Bill/Invoice"
      type="semiround"
      icon={<SearchIcon />}
      page="billlist"
    />
  );

  const bottomContent = (
    <Grid container spacing={1}>
      <Grid item xs={9}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="medium"
          fullWidth
          disabled={itemList.length <= 0 ? true : false}
          sx={{
            "&.Mui-disabled": {
              backgroundColor: "lightgray",
              color: "#405D72",
            },
          }}
        >
          Generate Bill
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          type="button"
          color="success"
          variant="contained"
          size="medium"
          fullWidth
          onClick={(e) => {
            dispatch(itemDrawerSet(true));
          }}
          onDoubleClick={(e) => {
            dispatch(accordionFlagSet(!accordionFlag));
          }}
        >
          <AddIcon /> Item
        </Button>
      </Grid>
    </Grid>
  );
  return (
    <>
      <AlertComp />
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        onChange={(e) => {
          onSubmitChange(e);
        }}
        autoComplete="off"
      >
        <FixedLayout header={headerContent} footer={bottomContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={style.paper}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Invoice Number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <TagIcon />
                          </InputAdornment>
                        ),
                        inputProps: {
                          onInput: (event) => handleMaxLengthInput(event, 10),
                        },
                        readOnly: true,
                      }}
                      {...register("billno", {})}
                      error={!!errors.billno}
                      helperText={errors.billno?.message}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        size="small"
                        defaultValue={selectedDate}
                        value={dayjs(selectedDate)}
                        onChange={handleDateChange}
                        slotProps={{
                          textField: {
                            size: "small",
                            InputProps: {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CalendarTodayIcon />
                                </InputAdornment>
                              ),
                            },
                          },
                        }}
                        format="DD-MM-YYYY"
                        maxDate={dayjs()}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Party Name"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                        inputProps: {
                          onInput: (event) => handleMaxLengthInput(event, 200),
                        },
                      }}
                      {...register("pname", {
                        required: "Party Name is required",
                        minLength: {
                          value: 3,
                          message:
                            "Party Name must be at least 3 characters long",
                        },
                      })}
                      error={!!errors.pname}
                      helperText={errors.pname?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Mobile Number"
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIphoneIcon />
                          </InputAdornment>
                        ),
                        inputProps: {
                          onInput: (event) => handleMaxLengthInput(event, 10),
                        },
                      }}
                      {...register("pmob", {
                        required: "Mobile Number is required",
                        minLength: {
                          value: 10,
                          message: "Mobile Number must be at least 10 digits",
                        },
                      })}
                      error={!!errors.pmob}
                      helperText={errors.pmob?.message}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <ItemBillList />
            </Grid>
            <Grid item xs={12}>
              <Paper className={style.paper}>
                <Grid container spacing={1.5}>
                  <Grid
                    item
                    xs={5}
                    sx={{ fontWeight: 600 }}
                    className={style.aclabel}
                  >
                    Item Subtotal
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeRoundedIcon fontSize="16px" />
                          </InputAdornment>
                        ),
                        inputProps: {
                          onInput: (event) => handleMaxLengthInput(event, 15),
                          style: {
                            textAlign: "end",
                            fontWeight: "bold",
                            color: "#405D72",
                          },
                        },
                        readOnly: true,
                      }}
                      {...register("itemtot", {})}
                    />
                  </Grid>
                  <Grid item xs={5} className={style.aclabel}>
                    <div
                      className={style.clearbtn}
                      onClick={() => {
                        dispatch(handleClearValue("raw", setValue));
                        setTimeout(() => {
                          dispatch(handleCalcBill(getValues(), setValue));
                        }, 500);
                      }}
                    >
                      &times;
                    </div>
                    <span>Raw</span>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      variant="standard"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Gm</InputAdornment>
                        ),
                        inputProps: {
                          inputMode: "decimal",
                          step: "any",
                          onInput: (event) => handleMaxLengthInput(event, 7),
                          onFocus: handleFocus,
                        },
                      }}
                      {...register("raw", {})}
                      onBlur={(e) => {
                        setValue("raw", Number(e.target.value).toFixed(3));
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeRoundedIcon fontSize="16px" />
                          </InputAdornment>
                        ),
                        inputProps: {
                          inputMode: "decimal",
                          step: "any",
                          onInput: (event) => handleMaxLengthInput(event, 15),
                          onFocus: handleFocus,
                          style: {
                            textAlign: "end",
                            color: "#405D72",
                          },
                        },
                        // readOnly:true,
                      }}
                      {...register("rawamt", {})}
                      onBlur={(e) => {
                        setValue("rawamt", Number(e.target.value).toFixed(2));
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{ fontWeight: 600 }}
                    className={style.aclabel}
                  >
                    Subtotal
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeRoundedIcon fontSize="16px" />
                          </InputAdornment>
                        ),
                        inputProps: {
                          onInput: (event) => handleMaxLengthInput(event, 15),
                          style: {
                            textAlign: "end",
                            fontWeight: "bold",
                            color: "#405D72",
                          },
                        },
                        readOnly: true,
                      }}
                      {...register("subtot", {})}
                    />
                  </Grid>
                  <Grid item xs={5} className={style.aclabel}>
                    <div
                      className={style.clearbtn}
                      onClick={() => {
                        dispatch(handleClearValue("descper", setValue));
                        setTimeout(() => {
                          dispatch(handleCalcBill(getValues(), setValue));
                        }, 500);
                      }}
                    >
                      &times;
                    </div>
                    <span>Discount</span>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      variant="standard"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <PercentIcon fontSize="16px" />
                          </InputAdornment>
                        ),
                        inputProps: {
                          inputMode: "decimal",
                          step: "any",
                          onInput: (event) => handleMaxLengthInput(event, 5),
                          onFocus: handleFocus,
                          style: {
                            textAlign: "end",
                          },
                        },
                      }}
                      {...register("descper", {})}
                      onBlur={(e) => {
                        setValue("descper", Number(e.target.value).toFixed(2));
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeRoundedIcon fontSize="16px" />
                          </InputAdornment>
                        ),
                        inputProps: {
                          inputMode: "decimal",
                          step: "any",
                          onInput: (event) => handleMaxLengthInput(event, 15),
                          style: {
                            textAlign: "end",
                            color: "#000",
                          },
                        },
                        // readOnly:true,
                      }}
                      {...register("descamt", {})}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{ fontWeight: 600 }}
                    className={style.aclabel}
                  >
                    Discount Total
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeRoundedIcon fontSize="16px" />
                          </InputAdornment>
                        ),
                        inputProps: {
                          onInput: (event) => handleMaxLengthInput(event, 15),
                          style: {
                            textAlign: "end",
                            fontWeight: "bold",
                            color: "#405D72",
                          },
                        },
                        readOnly: true,
                      }}
                      {...register("desctot", {})}
                    />
                  </Grid>
                  <Grid item xs={5} className={style.aclabel}>
                    <div
                      className={style.clearbtn}
                      onClick={() => {
                        dispatch(handleClearValue("gstper", setValue));
                        setTimeout(() => {
                          dispatch(handleCalcBill(getValues(), setValue));
                        }, 500);
                      }}
                    >
                      &times;
                    </div>
                    <span>GST</span>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      variant="standard"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <PercentIcon fontSize="16px" />
                          </InputAdornment>
                        ),
                        inputProps: {
                          inputMode: "decimal",
                          step: "any",
                          onInput: (event) => {
                            handleMaxLengthInput(event, 5);
                          },
                          onFocus: handleFocus,
                          style: {
                            textAlign: "end",
                          },
                        },
                      }}
                      {...register("gstper", {})}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeRoundedIcon fontSize="16px" />
                          </InputAdornment>
                        ),
                        inputProps: {
                          inputMode: "decimal",
                          step: "any",
                          onInput: (event) => handleMaxLengthInput(event, 15),
                          style: {
                            textAlign: "end",
                            fontWeight: "bold",
                          },
                        },
                      }}
                      {...register("gstamt", {})}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{ fontWeight: 600 }}
                    className={style.aclabel}
                  >
                    Total With GST
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeRoundedIcon fontSize="16px" />
                          </InputAdornment>
                        ),
                        inputProps: {
                          onInput: (event) => handleMaxLengthInput(event, 15),
                          style: {
                            textAlign: "end",
                            fontWeight: "bold",
                            color: "#405D72",
                          },
                        },
                        readOnly: true,
                      }}
                      {...register("gsttot", {})}
                    />
                  </Grid>
                  <Grid item xs={5} className={style.aclabel}>
                    <div className={style.clearbtn}>&#x2713;</div>
                    <span>Round Off</span>
                  </Grid>
                  <Grid item xs={3} className={style.aclabel}>
                    <Chip
                      label="-"
                      size="small"
                      color={isRoundoff === 1 ? "primary" : "default"}
                      sx={{ width: "40px" }}
                    />
                    <Chip
                      label="+"
                      size="small"
                      color={isRoundoff === 2 ? "primary" : "default"}
                      sx={{ width: "40px" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeRoundedIcon fontSize="16px" />
                          </InputAdornment>
                        ),
                        inputProps: {
                          onInput: (event) => handleMaxLengthInput(event, 4),
                          style: {
                            textAlign: "end",
                            fontWeight: "bold",
                          },
                        },
                      }}
                      {...register("roundoff", {})}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{ fontWeight: 600 }}
                    className={style.aclabel}
                  >
                    Net Amount
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeRoundedIcon fontSize="16px" />
                          </InputAdornment>
                        ),
                        inputProps: {
                          inputMode: "decimal",
                          step: "any",
                          onInput: (event) => handleMaxLengthInput(event, 15),
                          style: {
                            textAlign: "end",
                            fontWeight: "bold",
                            color: "#405D72",
                          },
                        },
                        readOnly: true,
                      }}
                      {...register("netamt", {})}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{ fontWeight: 600 }}
                    className={style.aclabel}
                  >
                    Received Amount
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeRoundedIcon fontSize="16px" />
                          </InputAdornment>
                        ),
                        inputProps: {
                          inputMode: "decimal",
                          step: "any",
                          onInput: (event) => handleMaxLengthInput(event, 15),
                          onFocus: handleFocus,
                          style: {
                            textAlign: "end",
                            color: "#000",
                          },
                        },
                      }}
                      {...register("recvamt", {})}
                      onBlur={(e) => {
                        setValue("recvamt", Number(e.target.value).toFixed(2));
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{ color: "red", fontWeight: 600 }}
                    className={style.aclabel}
                  >
                    Balance Amount
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      size="small"
                      fullWidth
                      type="number"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeRoundedIcon fontSize="16px" />
                          </InputAdornment>
                        ),
                        inputProps: {
                          inputMode: "decimal",
                          step: "any",
                          onInput: (event) => handleMaxLengthInput(event, 15),
                          onFocus: handleFocus,
                          style: {
                            textAlign: "end",
                            fontWeight: "bold",
                            color: "#405D72",
                          },
                        },
                        readOnly: true,
                      }}
                      {...register("balamt", {})}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </FixedLayout>
      </form>

      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            height: "360px",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          },
        }}
        open={itemDrawer}
        anchor="bottom"
        onClose={(e) => {
          dispatch(itemDrawerSet(false));
        }}
      >
        <ItemBill />
      </Drawer>
    </>
  );
};

export default Bill;
