import React, { useEffect, useState } from "react";
import style from "./Bill.module.css";
import {
  Grid,
  Paper,
  Button,
  TextField,
  Box,
  Drawer,
  Fab,
  InputAdornment,Checkbox,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useForm } from "react-hook-form";
import Bottombar from "../Appbar/Bottombar";
import { useNavigate } from "react-router-dom";
import ClientCard from "../Client/ClientCard";
import { useDispatch, useSelector } from "react-redux";
import { getBillList, handleBillMode } from "../../API/Reducer/billReducer";
import { formatUTCDate } from "../../MIS/Global";
import CloseIcon from "@mui/icons-material/Close";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Badge from '@mui/material/Badge';
import { handleBillPrintSetting } from "../../API/Reducer/billPrintReducer";
import FixedLayout from "../Layout/FixedLayout";

const BillList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { billData } = useSelector((state) => state.bill);
  const { billSetting } = useSelector((state) => state.global);
  const [draweropen, setDrawerOpen] = useState(false);
  const [fromDate, setfromDate] = useState(
    dayjs(formatUTCDate(new Date(), "ymd", billSetting?.defaultAgoDays))
  );
  const [toDate, settoDate] = useState(dayjs(formatUTCDate(new Date(), "ymd")));
  const [isFilterOn, setIsFilterOn] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [isDate, setIsDate] = useState(true);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      search: "",
    },
  });
  const onSubmit = (data) => {};
  const getData=(isfilter=false,isClear=0)=>{
    let data = {
      cmpid: localStorage.getItem("cmpid") || 0,
      isorder: "desc",
      daysago: billSetting?.defaultAgoDays,
    };
   
    if(isfilter===true){
      data={...data,
        fdate:formatUTCDate(new Date(fromDate),'ymd'),
        tdate:formatUTCDate(new Date(toDate),'ymd')};    
        delete data['daysago'];
        setIsFilterOn(true);
     }else{
      setfromDate(dayjs(formatUTCDate(new Date(), "ymd", billSetting?.defaultAgoDays)));
      settoDate(dayjs(formatUTCDate(new Date(), "ymd")));
      setIsFilterOn(false);
    }
    if(searchInput!==""){
      data={...data,srch:searchInput};
    }
    if(isDate===false && isClear===0){
      delete data['daysago'];
      delete data['fdate'];
      delete data['tdate'];
    }
    // alert(JSON.stringify(data));
    dispatch(getBillList(data));
    setSearchInput("");
   }
  useEffect(() => {
    getData();
    let data={
      devheading:"SHREE",
      cmpname:"Vaishnavi Jewellers",
      cmpaddress:"Jagdale Residancy, Shop No.4, Near Police Station Khanbhag, Sangli.",
      cmpmob:"+91 9552589909",
      gstno:"",
      ishallmark:1,
      logopath:"",
    };
    dispatch(handleBillPrintSetting(data))
  }, []);
  const handleDateChange = (newValue, istype) => {
    if (newValue) {    
      if (istype === "from") {
        setfromDate(dayjs(formatUTCDate(new Date(newValue), "ymd")));       
      } else {
        settoDate(dayjs(formatUTCDate(new Date(newValue), "ymd")));
      }
    }
  };
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  const filteredBillData = billData.filter((element) => {
    const searchStr = searchInput.toLowerCase();
    return (
      element.pname.toLowerCase().includes(searchStr) ||
      element.pmob.toLowerCase().includes(searchStr) ||
      String(element.billno).toLowerCase().includes(searchStr) ||
      String(element.netamt).toLowerCase().includes(searchStr)      
    );
  });   

  const headerContent = (
    <div className={style.billlistHeaderCon}>
    <Paper className={style.paper}>
      <div className={style.billlistHeader}>
      <TextField
        placeholder="search..."     
        size="small"             
        InputProps={{
          endAdornment: (
            <InputAdornment position="end"
             onClick={()=>{getData(false)}}>
              <SearchSharpIcon/>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        onChange={handleSearchInputChange}
        value={searchInput}
      />
       
        <Button
          type="button"
          size="medium"
          variant="contained"
          onClick={(e) => {
            dispatch(handleBillMode(0));
            navigate("/bill");
          }}
        >
          +New
        </Button>
      </div>
    </Paper>
    <Paper className={style.paper}>
      <div className={style.billlistHeader}>
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >    
         <Checkbox
           defaultChecked 
           size="medium"
           sx={{padding:0,margin:0}}
           color="primary"
           checked={isDate}
           onChange={(e)=>{setIsDate(e.target.checked)}}
           />

          <span style={{ color: "#4A249D" }}>DATE</span>
          <span style={{ color: "gray", fontSize: "14px" }}>
            {formatUTCDate(
              new Date(fromDate),
              "d3my",
              0
            )}{" "}
            - {formatUTCDate(new Date(toDate), "d3my", 0)}
          </span>
        </div>
        <div>
          <Badge badgeContent={isFilterOn===true?1:0} color="success">
          <FilterAltIcon
            sx={{ color: "#604CC3" }}
            onClick={() => {                        
              setDrawerOpen(!draweropen);
            }}
          />
          </Badge>
        </div>

      </div>
    </Paper>
  </div>
  )
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
      <FixedLayout header={headerContent} footer={<Bottombar/>}>
      <Grid container spacing={1}>
              {filteredBillData.map((element, index) => {
                let bgcolor = "#fff";
                if (index % 2 == 0) {
                  bgcolor = "#f3f7ec";
                }
                return (
                  <Grid item xs={12} md={12}>
                    <Paper
                      sx={{ backgroundColor: bgcolor }}
                      className={style.paper}
                    >
                      <ClientCard element={element} />
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
      </FixedLayout>
 
      </form>

      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          },
        }}
        open={draweropen}
        anchor="bottom"
        onClose={(e) => {
          setDrawerOpen(!draweropen);
        }}
      >
        <div className={style.filtermain}>
          <div className={style.closecon}>
            <div className={style.semiroundbtn} onClick={()=>{setDrawerOpen(!draweropen);}}>
              <CloseIcon/>
            </div>
          </div>
          <div className={style?.datecon}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From"
                size="small"
                defaultValue={fromDate}
                onChange={(newvalue) => {
                  handleDateChange(newvalue, "from");
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      ),
                    },
                  },
                }}
                format="DD-MM-YYYY"
               />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="To"
                size="small"
                defaultValue={toDate}
                onChange={(newvalue) => {
                  handleDateChange(newvalue, "to");
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      ),
                    },
                  },
                }}
                format="DD-MM-YYYY"
               />
            </LocalizationProvider>
          </div>
          <div className={style.drawerclose}>
            <Fab
              variant="extended"
              size="small"
              color="primary"
              className={style.fabcon}
              onClick={()=>{getData(true);setDrawerOpen(!draweropen);}}
            >
              <ThumbUpOffAltIcon />
              <span>Apply</span>
            </Fab>
            <Fab
              variant="extended"
              size="small"
              className={style.fabcon}
              sx={{backgroundColor:"#008598",color:"#fff"}}
              onClick={(e) => {
                 setIsDate(true);
                 setSearchInput(""); 
                  getData(false);
                 setDrawerOpen(!draweropen);
                
              }}
            >
              <CloseIcon/>
              <span>Clear</span>
            </Fab>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default BillList;
