export const GSRate=(grate=0,srate=0)=>{
  localStorage.setItem('grate',grate);
  localStorage.setItem('srate',srate);
}

export const numberToWords = (num) => {
  const units = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const teens = ["", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  const thousands = ["", "Thousand", "Lakh", "Crore"];

  if (num === 0) return "Zero";

  const numberToWordsRecursive = (n, index) => {
    if (n === 0) return "";
    if (n < 10) return units[n] + " ";
    if (n < 20) return teens[n - 10] + " ";
    if (n < 100) return tens[Math.floor(n / 10)] + " " + numberToWordsRecursive(n % 10, 0);
    if (n < 1000) return units[Math.floor(n / 100)] + " Hundred " + numberToWordsRecursive(n % 100, 0);

    let i = 0;
    let str = "";

    while (n > 0) {
      const rem = n % 1000;
      if (rem !== 0) {
        str = numberToWordsRecursive(rem, i) + thousands[i] + " " + str;
      }
      n = Math.floor(n / 1000);
      i++;
    }

    return str;
  };

  return numberToWordsRecursive(num, 0).trim() + " Only";
};

 