/* eslint-disable react/jsx-no-undef */
import React from "react";
import "./index.css";
import store from "../src/API/Store/store";
import { render } from "react-dom";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  unstable_HistoryRouter as Router,
} from "react-router-dom";
import { Provider } from "react-redux";
import HomePage from "./Components/HomePage/HomePage";
import ProductList from "./Components/Products/ProductList";
import DemoPage from "./Components/HomePage/DemoPage";
import NewParty from "./Components/Client/NewParty";
import Bill from "./Components/Bill/Bill";
import BillList from "./Components/Bill/BillList";
import BillOne from "./Components/Bill/BillPrint/BillOne";

const root = document.getElementById("root");
render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/productlist" element={<ProductList />} />      
          <Route path="/demo" element={<DemoPage/>} />
          <Route path="/client" element={<NewParty/>} />
          <Route path="/bill/:id?" element={<Bill/>} />
          <Route path="/billlist" element={<BillList/>}/>
          <Route path="/printone" element={<BillOne/>}/>                        
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  root
);

// s
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
