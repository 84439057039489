import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Grid,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CategoryIcon from "@mui/icons-material/Category";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom";
import style from "./Bottombar.module.css";

const MenuItem = ({ icon: Icon, label, isActive, onClick }) => (
  <div className={style.icons} onClick={onClick}>
    <Icon className={`${style.iconsize} ${isActive ? style.active : ""}`} />
    <h6 className={isActive ? style.active : ""}>{label}</h6>
  </div>
);
const Bottombar = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);
  
  const handleClick = (item) => {
    setActiveItem(item);
  };
  return (
    <>
    <div className={style.bottomcon}>       
       <MenuItem
          icon={HomeIcon}
          label="Home"
          isActive={activeItem === "Home"}
          onClick={() =>{handleClick("Home");navigate("/home")}}
        />       
       <MenuItem
          icon={PeopleAltIcon}
          label="Parties"
          isActive={activeItem === "Parties"}
          onClick={() =>{handleClick("Parties");navigate("/home")}}
        />      
       <MenuItem
          icon={CategoryIcon}
          label="Items"
          isActive={activeItem === "Items"}
          onClick={() =>{handleClick("Items");navigate("/home")}}
        />       
       <MenuItem
          icon={VolunteerActivismIcon}
          label="For You"
          isActive={activeItem === "For"}
          onClick={() =>{handleClick("For");navigate("/home")}}
        />    
       <MenuItem
          icon={MoreHorizIcon}
          label="More"
          isActive={activeItem === "More"}
          onClick={() =>{handleClick("More");navigate("/home")}}
        />
    </div>
     
    </>
  );
};

export default Bottombar;
