import React from 'react'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Paper } from '@mui/material';
import style from './Toppagebox.module.css'
const Toppagebox = (props) => {
    const navigate= useNavigate();
  return (
    <>
       <Paper elevation={3} style={{ padding: '10px', backgroundColor: '#fff' }}>
         <div className={style.main}>
            <div className={style.con}>
              <div className={style.headingCon}>
               <ArrowBackIcon sx={{color:"#604CC3"}}
                onClick={(e)=>{navigate("/"+props?.back)}}
               />
              <span className={style.itemcontext}>{props?.name}</span>
              </div>
              <div>
                {
                  props?.type==='semiround'?
                  <div className={style.semiroundbtn}
                  onClick={(e)=>{navigate("/"+props?.page);
                    props.func();
                  }}
                  >{props?.icon}</div>
                  :""
                }
              </div>
            </div>
         </div>
        
        </Paper>
    </>
  )
}

export default Toppagebox