import { createSlice } from "@reduxjs/toolkit";


const billPrintReducer = createSlice({
  name: "billprint",
  initialState: {
    billPrintSetting: {},
  },

  reducers: {
    setBillPrintSetting(state, action) {
      state.billPrintSetting = action.payload;
    },
    
  },
});

export const {setBillPrintSetting } = billPrintReducer.actions;
export default billPrintReducer.reducer;

export const handleBillPrintSetting = (data) => {
    return function actionData(dispatch) {    
        dispatch(setBillPrintSetting(data));        
    };
  };

 