import React from "react";
import { formatUTCDate } from "../../../MIS/Global";
import { numberToWords } from "../../../MIS/Globdata";
import hallmark from "../../../Assets/hallmark.png";
const BillOne = ({ data, items = [], printSetting }) => {
  // const dispatch = useDispatch();
  // const { billPrintSetting } = useSelector((state) => state.billprint);
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <div style={{textAlign:"center"}}>{printSetting?.devheading!==""?`|| ${printSetting?.devheading || "SHREE"} ||`:""}</div>
        <div style={{display:"flex",alignItems:"center"}}>
        <div style={{width:"25%"}}></div>
        <div
          style={{ width: "50%", fontSize: "xx-large", textAlign: "center" }}
        >
          {printSetting?.cmpname || "Vaishnavi Jewellers"}
        </div>
        <div style={{height:"100px",width:"25%",
        display:"flex",justifyContent:"flex-start",alignItems:"start",
        overflow:"hidden"
        }}>
          <img src={hallmark} style={{aspectRatio:"3/2",objectFit:"contain",
          width:"100%",height:"100%"
          }}/>
        </div>
        </div>
        

        <div style={{ display: "flex",borderTop:"1px solid #000" }}>
          <div style={{width:"50%",borderRight:"2px solid #000"}}>
            <table style={{width:"100%"}}>
              <tr>
                <td style={{fontSize:"small",fontWeight:600}}>Name-</td>
                <td style={{fontSize:"small",fontWeight:600}}>{printSetting?.cmpname || "Vaishnavi Jewellers"}</td>
              </tr>
              <tr>
                <td style={{fontSize:"small",fontWeight:600}}>Address-</td>
                <td style={{fontSize:"small",fontWeight:600}}>{printSetting?.cmpaddress || "819/A; main road D block, Sangli-416416"}</td>
              </tr>
              <tr>
                <td style={{fontSize:"small",fontWeight:600}}>State-</td>
                <td style={{fontSize:"small",fontWeight:600}}>{printSetting?.state || "Maharashtra"}</td>
              </tr>
              <tr>
                <td style={{fontSize:"small",fontWeight:600}}>GSTIN-</td>
                <td style={{fontSize:"small",fontWeight:600}}>{printSetting?.gstno || "27AAFFP3237XXXX"}</td>
              </tr>
            </table>
          </div>
          <div style={{width:"50%"}}>
          <table style={{width:"100%"}}>
              <tr>
                <td colSpan={2} style={{fontSize:"small",fontWeight:600,textAlign:"end"}}>
                  <span>Invoice No.- #{data?.billno || "01"}&nbsp; Date - {formatUTCDate(new Date(data?.edate || new Date()), "d3my", 0)}</span>
                </td>               
              </tr>
              <tr>
                <td style={{fontSize:"small",fontWeight:600}}>Name-</td>
                <td style={{fontSize:"small",fontWeight:600}}>{data?.pname || "Client full name"}</td>
              </tr>
              <tr>
                <td style={{fontSize:"small",fontWeight:600}}>Contact-</td>
                <td style={{fontSize:"small",fontWeight:600}}>{data?.pmob || "XXXXXXXXXX"}</td>
              </tr>
              <tr>
                <td style={{fontSize:"small",fontWeight:600}}>Address-</td>
                <td style={{fontSize:"small",fontWeight:600}}>{data?.address || "-"}</td>
              </tr>
            </table>
          </div>
        </div>

        <div style={{ height: "325px" }}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <tr style={{ border: "2px solid #000" }}>
              <th style={{ width: "5%", fontSize: "small" }}>SrNo.</th>
              <th
                style={{ width: "25%", textAlign: "left", fontSize: "small" }}
              >
                Particulars
              </th>
              <th style={{ width: "5%", fontSize: "small", textAlign: "left" }}>
                HSN
              </th>
              <th style={{ width: "5%", fontSize: "small" }}>Pcs.</th>
              <th style={{ width: "12%", fontSize: "small" }}>Rate</th>
              <th style={{ width: "12%", fontSize: "small" }}>GW</th>
              <th style={{ width: "12%", fontSize: "small" }}>NW</th>
              <th style={{ width: "12%", fontSize: "small" }}>
                Value
                <br /> Addtion
              </th>
              <th style={{ width: "12%", fontSize: "small" }}>Amount</th>
            </tr>
            <tbody>
              {Array.from(items.length <= 0 ? { length: 5 } : items).map(
                (element, index) => {
                  return (
                    <tr>
                      <td
                        style={{
                          width: "5%",
                          textAlign: "center",
                          fontSize: "small",
                        }}
                      >
                        {String(index + 1).length <= 1
                          ? `0${index + 1}`
                          : index + 1}
                      </td>
                      <td
                        style={{
                          width: "25%",
                          width: "150px",
                          fontSize: "small",
                        }}
                      >
                        {element?.prodname || "GOLD VEDHAN"}
                      </td>
                      <td style={{ width: "5%", fontSize: "small" }}>175456</td>
                      <td
                        style={{
                          width: "5%",
                          textAlign: "center",
                          fontSize: "small",
                        }}
                      >
                        {element?.qty || 1}
                      </td>
                      <td
                        style={{
                          width: "12%",
                          textAlign: "end",
                          fontSize: "small",
                        }}
                      >
                        {Number(element?.rate || 5120).toFixed(2)}
                      </td>
                      <td
                        style={{
                          width: "12%",
                          textAlign: "end",
                          fontSize: "small",
                        }}
                      >
                        {Number(element?.wt || 20.02).toFixed(3)}
                      </td>
                      <td
                        style={{
                          width: "12%",
                          textAlign: "end",
                          fontSize: "small",
                        }}
                      >
                        {Number(element?.nwt || 20.02).toFixed(3)}
                      </td>
                      <td
                        style={{
                          width: "12%",
                          textAlign: "end",
                          fontSize: "small",
                        }}
                      >
                        {Number(element?.majuriamt || 300).toFixed(2)}
                      </td>
                      <td
                        style={{
                          width: "12%",
                          textAlign: "end",
                          fontSize: "small",
                        }}
                      >
                        {Number(element?.netamt || 102802).toFixed(2)}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
        <div>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <tr style={{ border: "2px solid #000", lineHeight: "20px",borderRight: "none"  }}>
              <th style={{ width: "5%", fontSize: "small" }}></th>
              <th
                style={{ width: "25%", textAlign: "left", fontSize: "small" }}
              ></th>
              <th
                style={{ width: "5%", fontSize: "14px", textAlign: "left" }}
              ></th>
              <th style={{ width: "5%", fontSize: "small" }}></th>
              <th
                style={{ width: "12%", fontSize: "medium", textAlign: "end" }}
              >
                Total
              </th>
              <th style={{ width: "12%", fontSize: "small", textAlign: "end" }}>
                {Number(
                  items.reduce((acc, item) => acc + item?.wt, 0) || 20.02
                ).toFixed(3)}
              </th>
              <th style={{ width: "12%", fontSize: "small", textAlign: "end" }}>
                {Number(
                  items.reduce((acc, item) => acc + item?.nwt, 0) || 20.02
                ).toFixed(3)}
              </th>
              <th style={{ width: "12%", fontSize: "small", textAlign: "end" }}>
                {Number(
                  items.reduce((acc, item) => acc + item?.majuriamt, 0) || 20.02
                ).toFixed(2)}
              </th>
              <th style={{ width: "12%", fontSize: "small", textAlign: "end" }}>
                {Number(data?.itemtot || 1028303).toFixed(2)}
              </th>
            </tr>
          </table>
        </div>
        <div style={{ display: "flex", border: "2px solid #000",borderRight: "none"  }}>
          <div
            style={{
              width: "33.33%",
              borderRight: "1px solid #000",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <h5>Invoice Amount In Words:</h5>
            <h5>[{numberToWords(data?.netamt)}]</h5>
          </div>
          <div style={{ width: "33.33%", borderRight: "1px solid #000" }}>
            <h5 style={{ textAlign: "center" }}>Payment Detail:</h5>
          </div>
          <div style={{ width: "33.33%" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <tr>
                <th
                  style={{ width: "16%", fontSize: "14px", textAlign: "right" }}
                >
                  Mode
                </th>
                <th style={{ width: "4%", fontSize: "14px" }}>
                  {Number(data?.raw || 0).toFixed(3)}
                </th>
                <th
                  style={{ width: "12%", fontSize: "14px", textAlign: "end" }}
                >
                  {Number(data?.rawamt || 0).toFixed(2)}
                </th>
              </tr>
              <tr>
                <th
                  style={{ width: "16%", fontSize: "14px", textAlign: "right" }}
                >
                  Sub Total
                </th>
                <th
                  colSpan={2}
                  style={{ width: "16%", fontSize: "14px", textAlign: "end" }}
                >
                  {Number(data?.subtot || 0).toFixed(2)}
                </th>
              </tr>
              <tr style={{ display: `${data?.descper > 0 ? "" : "none"}` }}>
                <th
                  style={{ width: "16%", fontSize: "14px", textAlign: "right" }}
                >
                  Discount
                </th>
                <th style={{ width: "4%", fontSize: "14px" }}>
                  {Number(data?.descper || 0).toFixed(2)}%
                </th>
                <th
                  style={{ width: "12%", fontSize: "14px", textAlign: "end" }}
                >
                  {Number(data?.descamt || 0).toFixed(2)}
                </th>
              </tr>
              <tr style={{ display: `${data?.descper > 0 ? "" : "none"}` }}>
                <th
                  style={{ width: "16%", fontSize: "14px", textAlign: "right" }}
                >
                  Discount Total
                </th>
                <th
                  colSpan={2}
                  style={{ width: "16%", fontSize: "14px", textAlign: "end" }}
                >
                  {Number(data?.desctot || 0).toFixed(2)}
                </th>
              </tr>
              <tr style={{ display: `${data?.gstper > 0 ? "" : "none"}` }}>
                <th
                  style={{ width: "16%", fontSize: "14px", textAlign: "right" }}
                >
                  CGST&nbsp;{Number(data?.gstper / 2 || 0).toFixed(2)}%
                </th>
                <th
                  colSpan={2}
                  style={{ width: "16%", fontSize: "14px", textAlign: "end" }}
                >
                  {Number(data?.gstamt / 2 || 0).toFixed(2)}
                </th>
              </tr>
              <tr style={{ display: `${data?.gstper > 0 ? "" : "none"}` }}>
                <th
                  style={{ width: "16%", fontSize: "14px", textAlign: "right" }}
                >
                  SGST&nbsp;{Number(data?.gstper / 2 || 0).toFixed(2)}%
                </th>
                <th
                  colSpan={2}
                  style={{ width: "16%", fontSize: "14px", textAlign: "end" }}
                >
                  {Number(data?.gstamt / 2 || 0).toFixed(2)}
                </th>
              </tr>
              <tr style={{ display: `${data?.gstper > 0 ? "none" : "none"}` }}>
                <th
                  style={{ width: "16%", fontSize: "14px", textAlign: "right" }}
                >
                  IGST&nbsp;{Number(data?.gstper || 0).toFixed(2)}%
                </th>
                <th
                  colSpan={2}
                  style={{ width: "16%", fontSize: "14px", textAlign: "end" }}
                >
                  {Number(data?.gstamt || 0).toFixed(2)}
                </th>
              </tr>
              <tr style={{ display: `${data?.gstper > 0 ? "" : "none"}` }}>
                <th
                  style={{ width: "16%", fontSize: "14px", textAlign: "right" }}
                >
                  Total With GST
                </th>
                <th
                  colSpan={2}
                  style={{ width: "16%", fontSize: "14px", textAlign: "end" }}
                >
                  {Number(data?.gsttot || 0).toFixed(2)}
                </th>
              </tr>
              <tr>
                <th
                  style={{ width: "16%", fontSize: "14px", textAlign: "right" }}
                >
                  Round OFF
                </th>
                <th
                  colSpan={2}
                  style={{ width: "16%", fontSize: "14px", textAlign: "end" }}
                >
                  {Number(data?.roundoff || 0).toFixed(2)}
                </th>
              </tr>
              <tr>
                <th
                  style={{ width: "16%", fontSize: "14px", textAlign: "right" }}
                >
                  Net Amount
                </th>
                <th
                  colSpan={2}
                  style={{ width: "16%", fontSize: "14px", textAlign: "end" }}
                >
                  {Number(data?.netamt || 0).toFixed(2)}
                </th>
              </tr>
              <tr>
                <th
                  style={{ width: "16%", fontSize: "14px", textAlign: "right" }}
                >
                  Less Advance
                </th>
                <th
                  colSpan={2}
                  style={{ width: "16%", fontSize: "14px", textAlign: "end" }}
                >
                  {Number(data?.recvamt || 0).toFixed(2)}
                </th>
              </tr>
              <tr>
                <th
                  style={{ width: "16%", fontSize: "14px", textAlign: "right" }}
                >
                  Balance Advance
                </th>
                <th
                  colSpan={2}
                  style={{ width: "16%", fontSize: "14px", textAlign: "end" }}
                >
                  {Number(data?.balamt || 0).toFixed(2)}
                </th>
              </tr>
            </table>
          </div>
        </div>
        <div style={{bottom:0,display:"flex",justifyContent:"space-between"}}>
            <div style={{height:"125px",width:"50%",position:"relative",padding:"2px"}}>
              <h5 style={{bottom:0,position:'absolute'}}>Customer Sign</h5>
            </div>
            <div style={{height:"125px",width:"50%",
            position:"relative",padding:"2px",display:"flex",justifyContent:"end"}}>
              
              <div style={{bottom:0,position:'absolute',display:"flex",
                flexDirection:"column",alignItems:"center"}}>
                <h5>Authorized</h5>
                <h5>{printSetting?.cmpname || "Vaishnavi Jewellers"}</h5>
              </div>
              
            </div>
        </div>
      </div>
    </>
  );
};

export default BillOne;
