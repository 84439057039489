import { createSlice } from "@reduxjs/toolkit";
import { setConfirmDialogData, setRetAnswer } from "./confirmDialogReducer";
import {
  callAjax,
  formatDatetime_DMY,
  formatDatetime_YMD,
  formatUTCDate,
  useNavigatePage,
} from "../../MIS/Global";
import { reloadPage, setAlertData } from "./alertReducer";
import { v4 as uuid } from "uuid";
import Loader from "../../Components/loader/Loader";
import ReactDOM from "react-dom";
import dayjs from "dayjs";
import BillList from "../../Components/Bill/BillList";

const billReducer = createSlice({
  name: "bill",
  initialState: {
    billMode:0,
    itemDrawer: false,
    itemList: [],
    editItem: {},
    accordionFlag: true,
    calcBill: {},
    calcItem: {},
    isRoundoff: 0,
    billData: [],

  },

  reducers: {
    setItemDrawer(state, action) {
      state.itemDrawer = action.payload;
    },
    setAccordionFlag(state, action) {
      state.accordionFlag = action.payload;
    },
    setCalcBill(state, action) {
      state.calcBill = {
        ...state.calcBill,
        [action.payload.name]: action.payload.value,
      };
    },
    setIsRoundOff(state, action) {
      state.isRoundoff = action.payload;
    },
    setItemList(state, action) {
      const itemIndex = state.itemList.findIndex(
        (item) => item.id === action.payload.id
      );
      if (itemIndex !== -1) {
        // Update existing item
        state.itemList[itemIndex] = action.payload;
      } else {
        // Add new item
        state.itemList.push(action.payload);
      }
    },
    setUpdateItemList(state, action) {
      state.editItem = action.payload;
    },
    setUpdatedItemList(state, action) {
      state.itemList = action.payload;
    },
    setBillData(state, action) {
      state.billData = action.payload;
    },
    setBillMode(state,action){
      state.billMode=action.payload;
    }
  },
});

export const {
  setItemDrawer,
  setAccordionFlag,
  setCalcBill,
  setIsRoundOff,
  setItemList,
  setUpdateItemList,
  setUpdatedItemList,
  setBillData,setBillMode,
} = billReducer.actions;
export default billReducer.reducer;

export const handleBillMode = (data) => {
  return function actionData(dispatch) {
    dispatch(setBillMode(data));
     if(data===0){
       dispatch(setUpdatedItemList([]));
       dispatch(setBillData([]));
     }
  };
};

export const handleCalcBill = (data, setValue) => {
  return function actionData(dispatch) {
    let itemTot = data.itemtot;
    let rawAmt = data.rawamt;
    let descPer = data.descper;
    let gstPer = data.gstper;
    let recvAmt = data.recvamt;

    let calcSubTot = Number(Number(itemTot) - Number(rawAmt)).toFixed(2);
    setValue("itemtot", Number(itemTot).toFixed(2));
    setValue("subtot", calcSubTot);
    const discount = (calcSubTot * descPer) / 100;
    setValue("descamt", Number(discount).toFixed(2));
    const calcDiscAmt = calcSubTot - discount;
    setValue("desctot", Number(calcDiscAmt).toFixed(2));
    const gstDiscount = (calcDiscAmt * gstPer) / 100;
    setValue("gstamt", Number(gstDiscount).toFixed(2));
    const calcGstAmt = calcDiscAmt + gstDiscount;
    setValue("gsttot", Number(calcGstAmt).toFixed(2));

    const calcNetAmt = Math.round(calcGstAmt);
    const adjustment = (calcNetAmt - calcGstAmt).toFixed(2);
    setValue("roundoff", Number(adjustment).toFixed(2));
    setValue("netamt", Number(calcNetAmt).toFixed(2));
    const calcBalanceAmt = Number(calcNetAmt) - Number(recvAmt);
    setValue("balamt", Number(calcBalanceAmt).toFixed(2));

    if (adjustment < 0) {
      dispatch(setIsRoundOff(1));
    } else if (adjustment > 0) {
      dispatch(setIsRoundOff(2));
    } else {
      dispatch(setIsRoundOff(0));
    }

    // if (e && e.target) {
    //   dispatch(setCalcBill({ name: e.target.name, value: e.target.value }));
    // }
  };
};

export const handleClearValue = (name, setValue) => {
  return function actionData(dispatch) {
    setValue(name, 0);
  };
};
export const handleAutoRateCalc = (isType, curRate, setValue) => {
  return function actionData(dispatch) {
    let calcRate = 0;
    if (isType === 1) {
      calcRate = Number(curRate?.grate) / Number(10);
    } else {
      calcRate = Number(curRate?.srate) / Number(1000);
    }
    setValue("rate", Number(calcRate).toFixed(2));
  };
};
export const handleItemCalc = (data, setValue) => {
  return function actionData(dispatch) {
    let qty = data?.qty;
    let wt = data?.wt;
    let kwt = data?.kwt;
    let rate = data?.rate;
    let amount = data?.amount;
    let majurigm = data?.majurigm;
    let majuriamt = data?.majuriamt;

    let calcNetWt = Number(wt) - Number(kwt);
    calcNetWt = Number(calcNetWt) * Number(qty);
    setValue("nwt", Number(calcNetWt).toFixed(3));
    let calcAmount = Number(calcNetWt) * Number(rate);
    setValue("amount", Number(calcAmount).toFixed(2));
    let calcNetAmount = 0;
    let calcMajuriAmount = 0;
    if (Number(majurigm) <= Number(0)) {
      calcNetAmount = Number(calcAmount) + Number(majuriamt);
      setValue("netamt", Number(calcNetAmount).toFixed(2));
    } else {
      calcMajuriAmount = Number(majurigm) * Number(calcNetWt);
      setValue("majuriamt", Number(calcMajuriAmount).toFixed(2));
      calcNetAmount = Number(calcAmount) + Number(calcMajuriAmount);
      setValue("netamt", Number(calcNetAmount).toFixed(2));
    }
  };
};
export const handleNewItemList = (data) => {
  return function actionData(dispatch, getState) {
    ReactDOM.render(<Loader bal={true} />, document.getElementById("root1"));
    let itemData = {};
    const { itemList } = getState().bill;
    if (data?.id) {
      itemData = data;
    } else {
      itemData = { ...data, id: uuid() };
    }

    dispatch(setItemList(itemData));
    ReactDOM.render(<Loader bal={false} />, document.getElementById("root1"));
  };
};

export const handleDelteItemList =  (data, id) => {
  return async function actionData(dispatch) {
    const updatedItemList = data.filter((item) => item.id !== id);
    if (!isNaN(id) && !isNaN(parseInt(id))) {
      // if(data.length<=1){
      //   dispatch(
      //     setAlertData({
      //       type: "error",
      //       msg: "You can't delete this record; you can edit it or insert a new one, and then delete this entry.",
      //       code: 100,
      //     }));
      //   return;
      // }
      let res = await callAjax("pertdelete", {id:id});
      if(res?.data>0){
        dispatch(setUpdatedItemList(updatedItemList));
      }else{
        dispatch(
          setAlertData({
            type: "error",
            msg: "Something went wrong!Please try again",
            code: 100,
          })
        );
      }
    }else{
      dispatch(setUpdatedItemList(updatedItemList));
    }
  };
};
export const handleEditItemList = (data) => {
  return function actionData(dispatch) {
    dispatch(setUpdateItemList(data));
    dispatch(setItemDrawer(true));
  };
};

export const itemDrawerSet = (data) => {
  return function actionData(dispatch) {
    dispatch(setItemDrawer(data));
  };
};

export const accordionFlagSet = (data) => {
  return function actionData(dispatch) {
    dispatch(setAccordionFlag(data));
  };
};

export const saveBill = (data) => {
  let isError = 0;
  return async function actionData(dispatch, getState) {
    const { itemList } = getState().bill;
    data = { bill: data, pert: itemList };
    let url="bill";
    if (parseInt(data?.bill?.id) > 0) {
      url="billupdate"
    }
    // console.log(data)
    // alert(JSON.stringify(url))
    let res = await callAjax(url, data);
    if (res.data > 0) {
      dispatch(
        setAlertData({
          type: "success",
          msg: "Bill save successfully",
          code: 200,
        })
      );
      reloadPage("/bill");
    } else {
      dispatch(
        setAlertData({
          type: "error",
          msg: "Something went wrong!Please try again",
          code: 100,
        })
      );
    }
  };
};

export const getMaxBillNo = (data, setValue) => {
  let isError = 0;
  return async function actionData(dispatch) {
    let res = await callAjax("getbillno", data);
    if (res.code === 200) {
      setValue("billno", res?.data?.billno);
    } else {
      setValue("billno", 0);
    }
  };
};

export const getBillList = (data) => {
  return async function actionData(dispatch) {
    let res = await callAjax("getbills", data);
    if (res.code === 200) {
      dispatch(setBillData(res?.data));
    } else {
      dispatch(
        setAlertData({
          type: "error",
          msg: "Something went wrong!Please try again",
          code: 100,
        })
      );
    }
  };
};
export const getBillPert = (id, setValue, setSelectedDate) => {
  return async function actionData(dispatch, getState) {
    let { billData } = getState().bill;
    let filterBillData = billData.filter((item) => item.id == id);
    let dataObject = {};
    if (filterBillData.length <= 0) {
      dispatch(
        setAlertData({
          type: "error",
          msg: "Something went wrong!Please try again",
          code: 100,
        })
      );
      window.location.href = "/billlist";
      return;
    }
    dataObject = filterBillData?.[0];
    setSelectedDate(dayjs(formatUTCDate(new Date(dataObject?.edate), "ymd")));
    Object.keys(dataObject).forEach((key) => {
      setValue(key, dataObject[key]);
    });
    // dispatch(setBillData(dataObject));
    let res = await callAjax("getbillpert", { billid: id });
    if (res.code === 200) {
      dispatch(setUpdatedItemList(res?.data));
    } else {
      dispatch(
        setAlertData({
          type: "error",
          msg: "Something went wrong!Please try again",
          code: 100,
        })
      );
    }
  };
};

export const getBillPertPrint = (id) => {
  return async function actionData(dispatch, getState) {

    let res = await callAjax("getbillpert", { billid: id });
    if (res.code === 200) {
      dispatch(setUpdatedItemList(res?.data));
    } else {
      dispatch(
        setAlertData({
          type: "error",
          msg: "Something went wrong!Please try again",
          code: 100,
        })
      );
    }
  };
};


export const deleteBill = (data) => {
  return async function actionData(dispatch, getState) {
    const { billData } = getState().bill;
    const updatedItemList = billData.filter((item) => item.id !== data?.id);
    let res = await callAjax("billdelte", data);
    if (res.code === 200 && res?.data > 0) {
      dispatch(
        setAlertData({
          type: "success",
          msg: "Record deleted successfully",
          code: 200,
          duration: 500,
        })
      );
      setTimeout(() => {
        dispatch(setBillData(updatedItemList));
      }, 600);
    } else {
      dispatch(
        setAlertData({
          type: "error",
          msg: "Something went wrong!Please try again",
          code: 100,
        })
      );
    }
    dispatch(setRetAnswer({ resans: false }));
  };
};
