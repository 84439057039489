import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button, TextField, InputAdornment,RadioGroup,Radio,FormControlLabel, } from "@mui/material";
import { handleFocus, handleMaxLengthInput } from "../../MIS/Global";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import style from "./Bill.module.css";
import {handleAutoRateCalc, handleEditItemList, handleItemCalc, handleNewItemList, itemDrawerSet } from "../../API/Reducer/billReducer";
import RateBox from "../GlobalComponent/RateBox";
import { handleIsGS } from "../../API/Reducer/globalReducer";
const ItemBill = () => {
  const dispatch = useDispatch();
  const { editItem } = useSelector((state) => state.bill);
  const { isGS,rates } = useSelector((state) => state.global);

  const {
    control,
    register,
    handleSubmit,
    watch,
    setFocus,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      id: "",
      billid: 0,
      prodname: "",
      qty: 1,
      wt: 0,
      kwt: 0,
      nwt: 0,
      rate: 0,
      amount: 0,
      majurigm: 0,
      majuriamt: 0,
      netamt: 0,
      istype:isGS,
    },
  });
  const onSubmit = (data) => {
    dispatch(handleNewItemList(data));
    reset();
    dispatch(handleAutoRateCalc(isGS,rates,setValue));
  };
  const onError = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    setFocus(firstErrorField);
  };
  const onSubmitChange = (data) => { 
    setTimeout(() => { 
      dispatch(handleItemCalc(getValues(),setValue));
     }, 500);
  };
  
  useEffect(() => {
    dispatch(handleAutoRateCalc(isGS,rates,setValue));
  }, [])
  
  useEffect(() => {
    if(Object.keys(editItem).length>0){
      Object.keys(editItem).forEach((key) => {
        setValue(key, editItem[key]);
      });

      if (editItem?.istype !== undefined) {
         dispatch(handleIsGS(parseInt(editItem?.istype)));
      }
      
      dispatch(handleEditItemList({}));
    }
  },[editItem])
  

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit,onError)}
       onChange={(e)=>{onSubmitChange(e)}} autoComplete="off">
        <Grid container spacing={1} sx={{ padding: "10px" }}>
          <Grid
            item
            xs={1}
            className={style.itembackarrow}
            onClick={(e) => {
              dispatch(itemDrawerSet(false));
            }}
          >
            <ArrowBackIcon />
          </Grid>
          <Grid item xs={4}>
            <h2>Item</h2>
          </Grid>
          <Grid item xs={4}>
            <RateBox />
          </Grid>
          <Grid
            item
            xs={3}
            className={style.itemdelcon}
            onClick={(e) => {
              dispatch(itemDrawerSet(false));
            }}
          >
            <CloseIcon />
          </Grid>
           <Grid item xs={12}>
           <RadioGroup row
            defaultValue= {
              Object.keys(editItem).length>0 ?
              parseInt(editItem?.istype)===1?"gold":"silver"
              :
              isGS===1?"gold":"silver"
              }>
            <FormControlLabel value="gold" control={<Radio onClick={()=>{dispatch(handleAutoRateCalc(1,rates,setValue));
              setTimeout(() => { 
                dispatch(handleItemCalc(getValues(),setValue));
               }, 1000);
            }} onChange={(e)=>{
              dispatch(handleIsGS(1));
              setValue('istype',2);}}/>} label="Gold"/>
            <FormControlLabel value="silver" 
            control={<Radio onClick={()=>{dispatch(handleAutoRateCalc(2,rates,setValue));
            setTimeout(() => { 
                dispatch(handleItemCalc(getValues(),setValue));
               }, 1000);
            }} onChange={(e)=>{
              dispatch(handleIsGS(2));
              setValue('istype',2);
              }}/>} label="Silver"/>        
          </RadioGroup>
           </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Product"
              InputProps={{
                inputProps: {
                  onInput: (event) => handleMaxLengthInput(event, 100),
                },
              }}
              {...register("prodname", {
                required: "Product Name is required",
              })}
              error={!!errors.prodname}
              helperText={errors.prodname?.message}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              fullWidth
              variant="standard"
              type="number"
              label="Qty"
              InputProps={{
                inputProps: {
                  onInput: (event) => handleMaxLengthInput(event, 4),
                  onFocus: handleFocus,
                },
              }}
              {...register("qty", {
                required: "Quantity is required",
                validate: {
                  greaterThanZero: (value) =>
                  parseInt(value,10) > 0 ||
                    "Quantity must be greater than zero",
                },
              })}
              error={!!errors.qty}
              helperText={errors.qty?.message}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              fullWidth
              variant="standard"
              type="number"
              label="Weight"
              InputProps={{
                inputProps: {
                  inputMode: 'decimal',
                  step: 'any', // Allows any decimal step
                  onInput: (event) => handleMaxLengthInput(event, 6),
                  onFocus: handleFocus,
                  style: {
                    textAlign: "end",
                  },
                },
              }}
              {...register("wt", {
                required: "Weight is required",
                validate: {
                  greaterThanZero: (value) =>
                  parseFloat(value) > parseFloat(0) ||
                    "Weight must be greater than zero",
                },
              })}
              error={!!errors.wt}
              helperText={errors.wt?.message}               
               onBlur={(e)=>{
                setValue('wt',Number(e.target.value).toFixed(3));
               }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              fullWidth
              variant="standard"
              type="number"
              label="Khada Weight"
              InputProps={{
                inputProps: {
                  inputMode: 'decimal',
                  step: 'any',
                  onInput: (event) => handleMaxLengthInput(event, 6),
                  onFocus: handleFocus,
                  style: {
                    textAlign: "end",
                  },
                },
              }}
              {...register("kwt", {})}
              error={!!errors.kwt}
              helperText={errors.kwt?.message}       
              onBlur={(e)=>{
                setValue('kwt',Number(e.target.value).toFixed(3));
               }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              fullWidth
              variant="standard"
              type="number"
              label="Net Weight"
              InputProps={{
                inputProps: {
                  onInput: (event) => handleMaxLengthInput(event, 6),
                  style: {
                    textAlign: "end",
                    fontWeight: "bold",
                    color: "#405D72",
                  },
                },

                readOnly: true,
              }}
              {...register("nwt", {})}
              error={!!errors.nwt}
              helperText={errors.nwt?.message}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              fullWidth
              variant="standard"
              type="number"
              label="Rate"
              InputProps={{               
                inputProps: {
                  inputMode: 'decimal',
                  step: 'any',
                  onInput: (event) => handleMaxLengthInput(event, 10),
                  onFocus: handleFocus,
                  style: {
                    textAlign: "end",
                  },
                },
              }}
              {...register("rate", {
                required: "Rate is required",
                validate: {
                  greaterThanZero: (value) =>
                    parseInt(value, 10) > 0 || "Rate must be greater than zero",
                },
              })}
              error={!!errors.rate}
              helperText={errors.rate?.message}
              onBlur={(e)=>{
                setValue('rate',Number(e.target.value).toFixed(2));
               }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              fullWidth
              variant="standard"
              type="number"
              label="Amount"
              InputProps={{
                inputProps: {
                  onInput: (event) => handleMaxLengthInput(event, 10),
                  style: {
                    textAlign: "end",
                    fontWeight: "bold",
                    color: "#405D72",
                  },
                },
                readOnly: true,
              }}
              {...register("amount", {})}
              error={!!errors.amount}
              helperText={errors.amount?.message}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              fullWidth
              variant="standard"
              type="number"
              label="Majuri/gm"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end"
                   onClick={()=>{dispatch(handleAutoRateCalc(isGS,rates,setValue))}}
                  >
                    <span className={style.endicon}>/gm</span>
                  </InputAdornment>
                ),
                inputProps: {
                  inputMode: 'decimal',
                  step: 'any',
                  onInput: (event) => handleMaxLengthInput(event, 6),
                  onFocus: handleFocus,
                  style: {
                    textAlign: "end",
                  },
                },
              }}
              {...register("majurigm", {})}
              error={!!errors.majurigm}
              helperText={errors.majurigm?.message}      
               onBlur={(e)=>{
                setValue('majurigm',Number(e.target.value).toFixed(2));
               }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              fullWidth
              variant="standard"
              type="number"
              label="Majuri"
              InputProps={{             
                inputProps: {
                  inputMode: 'decimal',
                  step: 'any',
                  onInput: (event) => handleMaxLengthInput(event, 10),
                  onFocus: handleFocus,
                  style: {
                    textAlign: "end",
                  },
                },
              }}
              {...register("majuriamt", {})}
              error={!!errors.majuriamt}
              helperText={errors.majuriamt?.message}                
               onBlur={(e)=>{
                setValue('majuriamt',Number(e.target.value).toFixed(2));
               }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              fullWidth
              variant="standard"
              type="number"
              label="Net Amount"
              InputProps={{
                inputProps: {
                  onInput: (event) => handleMaxLengthInput(event, 10),
                  style: {
                    textAlign: "end",
                    fontWeight: "bold",
                    color: "#405D72",
                  },
                },
                readOnly: true,
              }}
              {...register("netamt", {})}
              error={!!errors.netamt}
              helperText={errors.netamt?.message}
            />
          </Grid>
          <Grid item xs={12} className={style.itemadd}>
            <Button type="submit" variant="contained">
              <AddIcon /> Add
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ItemBill;
