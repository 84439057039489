import React, { useState } from "react";
import style from "./RateBox.module.css";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, TextField, InputAdornment } from "@mui/material";
import { useForm, Controller, SubmitHandler, useWatch } from "react-hook-form";
import { handleMaxLengthInput } from "../../MIS/Global";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { handleRateUpdate } from "../../API/Reducer/globalReducer";

const RateBox = () => {
  const dispatch = useDispatch();
  const { rates } = useSelector((state) => state.global);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setFocus,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      grate: rates?.grate || 0,
      srate: rates?.srate || 0,
    },
  });
  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 1,    
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  };
  const onSubmit = (data) => {
    dispatch(handleRateUpdate(data));
    handleClose();
  };
  const onError = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    setFocus(firstErrorField);
  };
  return (
    <>
      <div className={style.main}>
        <span onClick={handleOpen}>&#x20b9;&nbsp;{rates?.grate}/10gm</span>
        <span onClick={handleOpen}>&#x20b9;&nbsp;{rates?.srate}/kg</span>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        sx={{margin:1}}
      >
        <Box sx={styles}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div
              onClick={() => {
                handleClose();
              }}
              className={style.modalclose}
            >
              <h2><u>Rate</u></h2>
              <span>&times;</span>
            </div>
            <div className={style.inpbox}>
              <TextField
                size="small"
                fullWidth
                label="Gold Rate"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyRupeeIcon fontSize="14px"/>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className={style.endicon}>/10Gm</span>
                    </InputAdornment>
                  ),
                  inputProps: {
                    onInput: (event) => handleMaxLengthInput(event, 10),
                  },
                }}
                {...register("grate", {})}
                error={!!errors.grate}
                helperText={errors.grate?.message}
              />
              <TextField
                size="small"
                fullWidth
                label="Silver Rate"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyRupeeIcon fontSize="14px"/>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className={style.endicon}>/kg</span>
                    </InputAdornment>
                  ),
                  inputProps: {
                    onInput: (event) => handleMaxLengthInput(event, 10),
                  },
                }}
                {...register("srate", {})}
                error={!!errors.srate}
                helperText={errors.srate?.message}
              />
            </div>
            <div className={style.btncon}>
              <Button onClick={()=>{onSubmit(getValues())}} type="button" variant="contained" color="success" size="small">
                Update
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default RateBox;
