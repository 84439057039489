/* eslint-disable no-undef */
import React, { useState } from "react";
import { TextField, InputAdornment, Button, Grid, Tabs, Tab, FormControl, InputLabel, MenuItem, TabContext,Box,TabList,TabPanel} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import style from './NewParty.module.css';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const NewParty = () => {
    const [value, setValue] = useState(0);
    return (
        <>

            <div>
                <div className={style.div1 }>
                    <Grid container spacing={2}>
                        <Grid item xs={1} className={style.arrowicon}>
                            <InputAdornment position="start" style={{ color: 'blue' }}>
                            <ArrowBackIcon />
                           </InputAdornment>
                        </Grid>
                        <Grid item xs={10} className={style.txt1}>
                            <p>
                               Create New Party
                            </p>
                        </Grid>
                        <Grid item xs={1} className={style.arrowicon}>
                            <InputAdornment style={{ color: 'blue' }} position="end" >
                                <SettingsApplicationsIcon />
                            </InputAdornment>
                        </Grid>
                 
                    </Grid>
                </div>

                <div className={style.div2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <TextField placeholder="Party Name" small fullWidth></TextField>
                        </Grid>
                    </Grid>
                </div>

                <div className={style.div3 }>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField placeholder="Contact Number." small fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3} className={style.btn}>
                            <Button variant="outlined"  style={{color:'blue'} } color="primary">
                                Customer
                            </Button>
                        </Grid>
                        <Grid item xs={3} className={style.btn}>
                            <Button variant="contained" disabled>
                                Supplier
                            </Button>
                        </Grid>
                    </Grid>
                </div>

                <div className={style.div4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={style.tabused}>
                            {/* <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="Item One" value="1" />
                                        <Tab label="Item Two" value="2" />
                                        <Tab label="Item Three" value="3" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">Item One</TabPanel>
                                <TabPanel value="2">Item Two</TabPanel>
                                <TabPanel value="3">Item Three</TabPanel>
                            </TabContext> */}
                            </Grid>
                    </Grid>
                </div>

                <div className={style.div5}>
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <TextField placeholder="Opening Balance" small fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3} className={style.btn}>
                            <Button variant="outlined" style={{ color: 'blue' }} color="primary">
                                I receive
                            </Button>
                        </Grid>
                        <Grid item xs={1} className={style.btn}>
                            <Button variant="contained" disabled>
                                I pay
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <div className={style.div6}>
                    <Grid container spacing={2 }>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Credit Period(Days)</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select"  label="Credit Period(Days)">
                                
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>

                <div className={style.div7}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField placeholder="Credit limit" small fullWidth></TextField>
                        </Grid>
                    </Grid>
                </div>
                <div className={style.div8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button color="primary" variant="contained" fullWidth>Save</Button>
                        </Grid>
                    </Grid>
                </div>
               
                
            </div>
        </>
    );
};

export default NewParty;
