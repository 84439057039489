import {
  Grid,
  Paper,
  TextField,
  Button,
  Fab,
  AppBar,
  Chip,
  Toolbar,
  Drawer,
  Avatar,
} from "@mui/material";
import React, { useState } from "react";
import InfoBoxPage from "../GlobalComponent/InfoBoxPage";
import { useNavigate } from "react-router-dom";
import MicIcon from "@mui/icons-material/Mic";
import { makeStyles } from "@material-ui/core/styles";
import style from "./HomePage.module.css";
import Bottombar from "../Appbar/Bottombar";
import HomePageMenu from "../Appbar/HomePageMenu";
import { handleBillMode } from "../../API/Reducer/billReducer";
import { useDispatch, useSelector } from "react-redux";
import FixedLayout from "../Layout/FixedLayout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

 const HomePage = () => {
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const { billPrintSetting } = useSelector((state) => state.billprint);
  const [draweropen, setDrawerOpen] = useState(false)
  const length = 0;
 
  // Create an array with the specified length
  const myArray = Array.from({ length }, (_, index) => index);

  const headerContent = (
    <div className={style?.userhomeheadcon}>
      <h4>{billPrintSetting?.cmpname}</h4>
      <div>
        <AccountCircleIcon className={style.avatar}/>
      </div>
    </div>
  )
  return (
    <>
     <FixedLayout header={headerContent} footer={<Bottombar/>}>
     <div className={style.buttonContainer}>
          <Chip label="Payment Received" size="medium" sx={{padding:"10px",backgroundColor:"#010127",color:"#fff"}}></Chip>
          <Fab onClick={(e)=>{setDrawerOpen(!draweropen)}} color="success" size="small" sx={{padding:"10px"}}>+</Fab>
          <Chip label="+Bill/Invoice" color="primary" size="medium" sx={{padding:"10px"}} onClick={(e)=>{dispatch(handleBillMode(0));navigate("/bill")}}></Chip>
        </div>
     </FixedLayout>
   
      {/* <div className={style.container}>
        <header className={style.header}>
          <Appbar />
        </header>
        <main className={style.content}>
            
        </main>
        <div className={style.buttonContainer}>
          <Chip label="Payment Received" size="medium" sx={{padding:"10px",backgroundColor:"#010127",color:"#fff"}}></Chip>
          <Fab onClick={(e)=>{setDrawerOpen(!draweropen)}} color="success" size="small" sx={{padding:"10px"}}>+</Fab>
          <Chip label="+Bill/Invoice" color="primary" size="medium" sx={{padding:"10px"}} onClick={(e)=>{dispatch(handleBillMode(0));navigate("/bill")}}></Chip>
        </div>
        <footer className={style.footer}>
          <Bottombar/>          
        </footer>
      </div> */}
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            height: "80vh",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          },
        }}
        open={draweropen}
        anchor="bottom"
        onClose={(e) => {
          setDrawerOpen(!draweropen)
        }}
      >
        <HomePageMenu/>
         <div className={style.drawerclose}>
         <Fab onClick={(e)=>{setDrawerOpen(!draweropen)}} color="info" size="medium" >X</Fab>
        </div>
      </Drawer>
    </>
  );
};

export default HomePage;
