import { createSlice } from "@reduxjs/toolkit"; 
import { setConfirmDialogData } from "./confirmDialogReducer";
import { callAjax, formatDatetime_DMY, formatDatetime_YMD } from "../../MIS/Global";
import { setAlertData } from "./alertReducer";
import { errorClear, errorSet } from "./errorReducer";
import { GSRate } from "../../MIS/Globdata";
  

const globalReducer = createSlice({
    name: 'global',
    initialState: {
        rates:{grate:localStorage.getItem('grate')||0,
               srate:localStorage.getItem('srate')||0},     
        isGS:1,    
        billSetting:{
            defaultAgoDays:15,        
        }
    },
    reducers: {           
         setRates(state, action) {
            state.rates = action.payload;
        },
        setIsGS(state, action) {
            state.isGS = action.payload;
        },
                     
    }
});

export const {setRates,setIsGS} = globalReducer.actions;
export default globalReducer.reducer;

export const handleRateUpdate = (data) => {
    return function actionData(dispatch) {
       dispatch(setRates(data));
       GSRate(data?.grate,data?.srate);
    };
  };

  export const handleIsGS = (data) => {
    return function actionData(dispatch) {
       dispatch(setIsGS(data));
    };
  };

