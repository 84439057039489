import React, { useEffect, useState,useCallback  } from "react";
import Grid from "@mui/material/Grid";
import style from "./ClientCard.module.css";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Button from "@mui/material/Button";
import {Divider } from "@mui/material";
import { formatUTCDate } from "../../MIS/Global";
import ReduxConfirmationDialog from "../ConfirmationDialog/ReduxConfirmationDialog";
import { setConfirmDialogData } from "../../API/Reducer/confirmDialogReducer";
import { useDispatch, useSelector } from "react-redux";
import { deleteBill, getBillPertPrint, setUpdatedItemList } from "../../API/Reducer/billReducer";
import AlertComp from "../AlertMessageCom/AlertComp"
import { useNavigate } from "react-router-dom";
import generatePDF, { Resolution, Margin, Options } from "react-to-pdf";
import ReactDOM from "react-dom";
import html2pdf from "html2pdf.js";
import BillOne from "../Bill/BillPrint/BillOne";
import ReactDOMServer from "react-dom/server";
import Loader from "../loader/Loader";

const ClientCard = ({element}) => {
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const {type,resans} = useSelector(state => state.confirm);
  const { billPrintSetting } = useSelector((state) => state.billprint);
  const { itemList } = useSelector((state) => state.bill);
  const [billid, setbillid] = useState(0);
  const [printData, setPrintData] = useState({});
  const [fileName, setfileName] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if(type==='billdel' && resans===true){
        dispatch(deleteBill({id:billid}));
    }
  }, [resans])
  const getFormattedDateTime = () => {
    const now = new Date();
  
    // Extract day, month, year, hours, and minutes
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(now.getFullYear()).slice(-2); // Get last two digits of the year
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
  
    // Format as ddmmyy hh:mm
    return `${day}${month}${year}_${hours}:${minutes}`;
  };
  

  const downloadPdf = useCallback(async (filename, data, items) => {
    try {
      setLoading(true);
       const htmlEmail = ReactDOMServer.renderToStaticMarkup(
        <BillOne data={data} items={items} printSetting={billPrintSetting} />
      );
      const element = htmlEmail;

      const options = {
        margin: 0.3,
        filename: `${filename}.pdf`,
        html2canvas: { scale: 1 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      };

      await html2pdf().from(element).set(options).save();
      dispatch(setUpdatedItemList([]));
      setPrintData([]);
      setfileName("");
    } catch (error) {
      console.error('Error generating or downloading PDF:', error);
    } finally {
      setLoading(false);
      dispatch(setUpdatedItemList([]));
      setPrintData([]);
      setfileName("");
     }
  }, [billPrintSetting, dispatch]);

  // useEffect(() => {
  //   if(itemList.length>0){
  //      downloadPdf(fileName,printData,itemList)
  //   }
  // }, [itemList])

  useEffect(() => {
    if (itemList.length > 0 && printData && fileName) {
      downloadPdf(fileName, printData, itemList);
    }
  }, [itemList, printData, fileName, downloadPdf]);
  
  
  return (
    <>
    <AlertComp/>
    <ReduxConfirmationDialog/>
    {loading && <Loader />}
       <div className={style.main} onClick={(e)=>{
          e.stopPropagation();
          navigate("/bill/"+element?.id);
       }}>
         <div className={style?.namecon}>
            <div>{element?.pname}-{element?.pmob}             
            </div>
            <div><span>&#8377;</span>{Number(element?.netamt).toFixed(2)}</div>
         </div>
         <div className={style?.con1}>
            <div>
             Invoice #{element?.billno}
            </div>
            <div>
            {/* <span style={{textAlign:"left"}}>Received</span> */}
            <span>&#8377;</span>
             {Number(element?.recvamt).toFixed(2)}
            </div>
         </div>
         <div className={style?.con2}>
            <div>
              {formatUTCDate(new Date(element?.edate),"d3my",0)}
            </div>
            <div>     
            {
              element?.balamt > 0 ?
             <span className={style?.unpaidcon}>&#8377;{element?.balamt}</span>
             :<span className={style?.paidcon}>Paid</span>
            }        
            </div>
         </div>
         <div className={style?.con3}>
           <Divider sx={{color:"gray"}}/>
         </div>
         <div className={style?.con4}>
             <div onClick={(e)=>{
              e.stopPropagation();
              dispatch(setConfirmDialogData({
              msg:"Do you want to delete this record?",yeslbl:"Yes",nolbl:"No",heading:"Delete Confirmation",
              openclose:true,type:"billdel"}));
              setbillid(element?.id);
              }}><DeleteIcon color="error"/></div> 
             <div>
              <WhatsAppIcon color="success" sx={{display:"none"}}
               onClick={(e)=>{e.stopPropagation();}}/>
              <PictureAsPdfIcon sx={{color:"blueviolet"}}
               onClick={(e)=>{
                e.stopPropagation();
                let fileName=element?.pname+"_#"+element?.billno+"_"+getFormattedDateTime();                
                // downloadPdf(fileName,element);
                setPrintData(element);
                setfileName(fileName);
                dispatch(getBillPertPrint(element?.id))
               }}
              />
             </div> 
         </div>
       </div>
    </>
  );
};

export default ClientCard;
