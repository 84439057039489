import React from "react";
import { Grid, Chip, Paper,Divider } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import style from "./Bill.module.css";
import { accordionFlagSet, handleDelteItemList, handleEditItemList } from "../../API/Reducer/billReducer";

const ItemCard = ({bgcolor,element,itemList}) => {
  const dispatch = useDispatch();
   return (
    <>
      <Grid container spacing={1} sx={{ backgroundColor: bgcolor }}>
        <Grid item xs={8} md={8} className={style.itemcardHeading}>
          {element?.prodname}
        </Grid>
        <Grid item xs={4} md={4} className={style.itemRate}>
          &#8377;{element?.netamt}
        </Grid>
        <Grid item xs={4} md={4} className={style.itemDetails}>
          Qty x Rate
        </Grid>
        <Grid item xs={8} md={8} className={style.itemDetails}>
          {element?.qty}{"pcs"} {"x"} {element?.rate}
        </Grid>
        <Grid item xs={4} md={4} className={style.itemDetails}>
          Grs x Net Wt.
        </Grid>
        <Grid item xs={4} md={4} className={style.itemDetails}>
          {element?.wt} x {element?.nwt}
        </Grid>
        <Grid item xs={4} md={4} className={style.itemEditDel}>
          <Chip onClick={(e)=>{dispatch(handleEditItemList(element))}} variant="outlined" label="EDIT" size="small" color="primary" />
          <Chip onClick={(e)=>{dispatch(handleDelteItemList(itemList,element?.id))}} variant="outlined" label="DEL" size="small" color="error" />
        </Grid>
        <Grid item xs={12}>
         <hr/>
        </Grid>
      </Grid>
    </>
  );
};

const ItemBillList = () => {
  const dispatch = useDispatch();
  const { itemList, accordionFlag } = useSelector((state) => state.bill);
  
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Accordion expanded={accordionFlag}
           onChange={(e)=>{dispatch(accordionFlagSet(!accordionFlag))}}
           >
            <AccordionSummary
              expandIcon={<ExpandMore/>}
              aria-label="Expand"
              aria-controls="-content"
              id="-header"
            >
              <Typography className={style?.itemheading}>
                ITEMS ({itemList.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={style.itemlistcon}>              
                {itemList.map((element, index) => {
                   let bgcolor="#fff";
                   if(index % 2==0){bgcolor="aliceblue";}
                  return <ItemCard itemList={itemList} bgcolor={bgcolor} element={element}/>;
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default ItemBillList;
