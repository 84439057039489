import { configureStore } from "@reduxjs/toolkit";
import confirmDialogReducer from '../Reducer/confirmDialogReducer';
import alertReducer from "../Reducer/alertReducer";
import errorReducer from "../Reducer/errorReducer";
import userReducer from "../Reducer/userReducer";
import billReducer from "../Reducer/billReducer";
import globalReducer from "../Reducer/globalReducer";
import billPrintReducer from "../Reducer/billPrintReducer";


const store = configureStore({
    reducer: {
        confirm:confirmDialogReducer,
        alert:alertReducer,
        error:errorReducer,
        user:userReducer,
        bill:billReducer,
        global:globalReducer,
        billprint:billPrintReducer,
    }
});

export default store;