import { createSlice } from "@reduxjs/toolkit"; 
import { setConfirmDialogData } from "./confirmDialogReducer";
import { callAjax, formatDatetime_DMY, formatDatetime_YMD } from "../../MIS/Global";
import { setAlertData } from "./alertReducer";
import { errorClear, errorSet } from "./errorReducer";
  

const userReducer = createSlice({
    name: 'user',
    initialState: {
        user:{},   
        userData:[],     
    },

    reducers: {           
         setUser(state, action) {
            state.user = {
              ...state.user,
              [action.payload.name]: action.payload.value,
            };
        },
                     
    }
});

export const { setUser} = userReducer.actions;
export default userReducer.reducer;
 
export const handleUserChange = (e, name) => {
    return function actionData(dispatch) {
      if (e && e.target) {
        dispatch(setUser({ name: e.target.name, value: e.target.value }));
      }
    };
  };

export const saveUser = (data) => {
    let isError=0;    
    return async function actionData(dispatch) {      
      
      // let res=await callAjax("getuser",{id:1});  
      let res=await callAjax("register",data);
      alert(JSON.stringify(res))

    };
  }
